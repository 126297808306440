import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/molecules/page-header';
import Intro from '../components/molecules/intro';
import { getBlogUrl } from '../utils/urls';

const NotFoundPage = ({ title, introTitle, introBody }) => (
  <Layout>
    <SEO title={title} />
    <PageHeader title={title} />
    <Intro title={introTitle} body={introBody} />
  </Layout>
);

NotFoundPage.defaultProps = {
  title: 'Page not found',
  introTitle: 'Oh no, looks like someone’s torn this page out.  ',
  introBody: `<p>Well, I suppose that happens during the drafting process. I’m sure you can relate. Anyhoo, why not see if you can find what you were looking for over on the <a href="${getBlogUrl()}">Blog</a>?</p>`,
};

export default NotFoundPage;
