export function getTaxonomyUrl(prefix, taxonomy, term) {
  return `${prefix}/${taxonomy}/${term}`;
}

export function getBlogUrl() {
  return '/blog';
}

export function getResourcesUrl() {
  return '/resources';
}
